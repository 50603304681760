/* eslint no-await-in-loop: 0 */
/* eslint class-methods-use-this: 0 */
import { capitalDelta, t, utils } from '@elastic-dao/sdk';
import { ethers } from 'ethers';
import { get, writable } from 'svelte/store';
import { isAddress } from '@pie-dao/utils';

import { balances, key } from './balances';
import { oracle } from './oracle';
import { organization } from './organization';
import { subject } from '../subeth';
import sdk from '../config/sdk';

const { toKey } = utils;

export const organizations = {
  status: writable({
    initialized: true,
    loading: true,
    version: 1,
  }),
};

export const loading = (bool) => {
  organizations.status.set({ ...get(organization.status), loading: bool });
};

class LiveOrganizations {
  constructor() {
    this.account = writable({
      address: ethers.constants.AddressZero,
      balances: [],
    });
    this.accounts = writable({});
    this.accountUpdatePid = undefined;
    this.keys = writable({});

    // subject('blockNumber').subscribe(() => {
    //   this.updateTokens();
    // });
    balances.subscribe(this.queueAccountUpdate.bind(this));
  }

  // Actions
  async add(daoAddress) {
    const address = toKey(daoAddress);

    if (get(this.keys)[address]) {
      return;
    }

    // Load initial objects
    const dao = await sdk.models.DAO.deserialize(address);
    const token = await dao.token();
    const { ecosystem } = dao;

    // Store SDK objects
    const sdkObjectsKey = this.sdkObjectsKey(address);
    const sdkObjects = {
      dao,
      ecosystem,
      token,
      tokenHolders: {},
    };
    this[sdkObjectsKey] = sdkObjects;

    // Store DAO attributes as a svelte store
    const daoStoreKey = this.daoStoreKey(address);
    this[daoStoreKey] = dao;

    // Store Ecosystem attributes as a svelte store
    const ecosystemStoreKey = this.ecosystemStoreKey(address);
    this[ecosystemStoreKey] = ecosystem;

    // Store Token attributes as a svelte store
    const tokenStoreKey = this.tokenStoreKey(address);
    this[tokenStoreKey] = token;

    // Gather keys
    const newKeys = {};
    newKeys[address] = {
      daoStoreKey,
      ecosystemStoreKey,
      sdkObjectsKey,
      tokenStoreKey,
      tokenHolders: {},
    };

    // Store TokenHolder attributes for all accounts
    const accounts = get(this.accounts);
    const tokenHolders = await Promise.all(
      Object.keys(accounts).map((account) =>
        sdk.models.TokenHolder.deserialize(account, ecosystem, token),
      ),
    );

    for (let i = 0; i < tokenHolders.length; i += 1) {
      const tokenHolder = tokenHolders[i];
      sdkObjects.tokenHolders[tokenHolder.account.toLowerCase()] = tokenHolder;
      const tokenHolderStoreKey = this.tokenHolderStoreKey(
        address,
        tokenHolder.account,
      );
      this[tokenHolderStoreKey] = tokenHolder;
      newKeys[address].tokenHolders[
        toKey(tokenHolder.account)
      ] = tokenHolderStoreKey;
    }

    // Update keys svelte store
    this.keys.set({ ...get(this.keys), ...newKeys });
  }

  async addAccount(accountAddress) {
    const account = toKey(accountAddress);

    if (get(this.accounts)[account]) {
      return;
    }

    // TODO: track tokenHolder object for every DAO

    const keys = get(this.keys);
    const daoAddresses = Object.keys(keys);

    for (let i = 0; i < daoAddresses.length; i += 1) {
      const address = toKey(daoAddresses[i]);
      const sdkObjectsKey = this.sdkObjectsKey(address);
      const { ecosystem, token } = this[sdkObjectsKey];
      const tokenHolder = await sdk.models.TokenHolder.deserialize(
        account,
        ecosystem,
        token,
      );

      this[sdkObjectsKey].tokenHolders[
        toKey(tokenHolder.account)
      ] = tokenHolder;

      const tokenHolderStoreKey = this.tokenHolderStoreKey(
        address,
        tokenHolder.account,
      );
      this[tokenHolderStoreKey] = tokenHolder;

      const newKeys = {};
      const currentKeys = get(liveOrganizations.keys);
      newKeys[address] = { ...currentKeys[address] };
      newKeys[address].tokenHolders[
        toKey(tokenHolder.account)
      ] = tokenHolderStoreKey;

      // Update keys svelte store
      this.keys.set({ ...get(this.keys), ...newKeys });
    }

    const accountsUpdate = {};
    accountsUpdate[account] = true; // TODO: maybe balance tracking here
    this.accounts.set({ ...get(this.accounts), ...accountsUpdate });
  }

  async changeAccount(address) {
    const accountData = get(this.account);
    const newAddress = address.toLowerCase();

    if (accountData.address === newAddress) {
      return;
    }

    await this.addAccount(address);

    this.updateAccount(get(balances), address);
  }

  daoStoreKey(address) {
    return toKey(address, 'DAO');
  }

  ecosystemStoreKey(address) {
    return toKey(address, 'Ecosystem');
  }

  queueAccountUpdate(balanceData) {
    clearTimeout(this.accountUpdatePid);
    this.accountUpdatePid = setTimeout(() => {
      this.updateAccount(balanceData);
    }, 500);
  }

  sdkObjectsKey(address) {
    return toKey(address, 'SDKObjects');
  }

  tokenHolderStoreKey(address, accountAddress) {
    return toKey(address, 'TokenHolder', accountAddress);
  }

  tokenStoreKey(address) {
    return toKey(address, 'Token');
  }

  updateAccount(balanceData, newAddress) {
    const address = (newAddress || get(this.account).address).toLowerCase();

    if (address === ethers.constants.AddressZero) {
      return;
    }

    const updates = {
      address,
      balances: [],
    };

    const ethUSDPrice = get(oracle).eth;

    const accountETHBalance = balanceData[key(address)];
    updates.balances.push({
      address: ethers.constants.AddressZero,
      amount: accountETHBalance,
      name: 'Ether',
      symbol: 'ETH',
      valueETH: accountETHBalance,
      valueUSD: accountETHBalance.multipliedBy(ethUSDPrice),
    });

    const keys = get(this.keys);
    const daoAddresses = Object.keys(keys);

    for (let i = 0; i < daoAddresses.length; i += 1) {
      const daoAddress = daoAddresses[i];

      if (isAddress(daoAddress)) {
        const { sdkObjectsKey } = keys[daoAddress];
        const { token, tokenHolders } = this[sdkObjectsKey];
        const { k, lambda, m, name, symbol } = token;
        const tokenHolder = tokenHolders[address];

        const accountTBalance = t(tokenHolder.lambda, m, k);
        const daoETHBalance = balanceData[key(daoAddress)];
        const daoTBalance = t(lambda, m, k);

        const capitalDeltaValue = capitalDelta(daoETHBalance, daoTBalance);

        const accountTValueETH = accountTBalance.multipliedBy(
          capitalDeltaValue,
        );
        const accountTValueUSD = accountTValueETH.multipliedBy(ethUSDPrice);

        if (!accountTValueETH.isNaN() && !accountTValueUSD.isNaN()) {
          updates.balances.push({
            name,
            symbol,
            address: token.uuid,
            amount: accountTBalance,
            valueETH: accountTValueETH,
            valueUSD: accountTValueUSD,
          });
        }
      }
    }

    this.account.set(updates);
  }

  updateTokens() {
    const keys = get(this.keys);
    const daoAddresses = Object.keys(keys);

    for (let i = 0; i < daoAddresses.length; i += 1) {
      const daoAddress = daoAddresses[i];

      if (isAddress(daoAddress)) {
        const { sdkObjectsKey } = keys[daoAddress];
        const { token } = this[sdkObjectsKey];
        token.refresh();
      }
    }
  }
}

export const liveOrganizations = new LiveOrganizations();
