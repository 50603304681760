export const edaoTestnet = {
  blocknative: {
    dappId: 'ded3dba9-5677-4c6f-9e12-2cd58ff163a1',
    networkId: 1,
  },
  etherscan: {
    apiKey: '9I14Q5JKW8N86ZP87PEVREQGFDGIZK7QG5',
  },
  factoryAddress: '0x7594d6F31F685DcCcE8C00FCe91a2071Edd42524',
};

export const localhost = {
  blocknative: {
    dappId: 'ded3dba9-5677-4c6f-9e12-2cd58ff163a1',
    networkId: 1,
  },
  etherscan: {
    apiKey: '9I14Q5JKW8N86ZP87PEVREQGFDGIZK7QG5',
  },
  factoryAddress: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
};

export const ropsten = {
  blocknative: {
    dappId: 'ded3dba9-5677-4c6f-9e12-2cd58ff163a1',
    networkId: 3,
  },
  etherscan: {
    apiKey: '9I14Q5JKW8N86ZP87PEVREQGFDGIZK7QG5',
  },
  factoryAddress: '0x08b6581F3A3315D3Bec69f01A9Fd7E497CCf398B',
};

export const conditionalEnv = () => {
  let env = localhost;

  // ENV_NETWORK is replace by rollup, so can be safely ignored by eslint
  // eslint-disable-next-line no-undef
  if (ENV_NETWORK === 'edaoTestnet') {
    env = edaoTestnet;
  } else if (ENV_NETWORK === 'ropsten') {
    env = ropsten;
  }

  return env;
};
